import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/redux/store';
import { checkTitle } from '@/redux/slices/titleSlice';
import { ReactComponent as PlayIcon } from '@/icons/play.svg';
import { Row, Select, Option, Button, IconWrapper } from './TitleButtonsStyles';
// import { Container, Heading } from '../my-list/MyListStyles';

interface Season {
  air_date: string | null;
  episode_count: number;
  id: number;
  name: string;
  overview: string;
  poster_path: string | null;
  season_number: number;
  vote_average: number;
}

const SeriesButton: React.VFC<{ id: string; title: string; mediaType: string; posterPath: string }> = ({
  id,
  title,
  mediaType,
  posterPath,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const videos = useSelector((state) => state.title.videos);
  const fbId = useSelector((state) => state.title.firebaseId) as string;

  useEffect(() => {
    if (userId && !fbId) dispatch(checkTitle(userId, id, mediaType));
  }, [userId, fbId, id, mediaType, dispatch]);

  const [seasons, setSeasons] = useState([] as Season[]);
  const [selectedSeason, setSelectedSeason] = useState<number | null>(null);
  const [selectedEpisode, setSelectedEpisode] = useState<number | null>(null);

  useEffect(() => {
    const fetchSeasons = async () => {
      const response = await fetch(`https://api.themoviedb.org/3/tv/${id}?api_key=36eef0ff158372cf7575c022aa83ad22`);
      const data = await response.json();
      if (response.ok) {
        setSeasons(data.seasons);
      } else {
        console.error('Failed to fetch seasons:', data.status_message);
      }
    };

    fetchSeasons();
  }, [id]);

  const handleWatch = () => {
    if (selectedSeason && selectedEpisode) {
        history.push(`/series/watch/${id}/${selectedSeason}/${selectedEpisode}`);
    } else {
      alert('Please select both season and episode');
    }
  };

  return (
    <Row>
      {videos.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Select
              value={selectedSeason || ''}
              style={{ margin: '10px', padding: '10px', width: '50%', fontFamily: 'Arial', fontSize: '14px' }}
              onChange={(e) => setSelectedSeason(parseInt(e.target.value))}
            >
              <Option value="">-- Select Season --</Option>
              {seasons.map((season) => (
                season.season_number !== 0 && (
                  <Option
                    key={season.id}
                    value={season.season_number}
                    style={{ fontFamily: 'Arial', fontSize: '14px' }}
                  >
                    Season {season.season_number}
                  </Option>
                )
              ))}
            </Select>
            {selectedSeason && (
              <Select
                value={selectedEpisode || ''}
                style={{ margin: '10px', padding: '10px', width: '50%', fontFamily: 'Arial', fontSize: '14px' }}
                onChange={(e) => setSelectedEpisode(parseInt(e.target.value))}
              >
                <Option value="">-- Select Episode --</Option>
                {seasons.find((season) => season.season_number === selectedSeason)?.episode_count &&
                  Array.from(
                    { length: seasons.find((season) => season.season_number === selectedSeason)!.episode_count },
                    (_, index) => (
                      <Option
                        key={index + 1}
                        value={index + 1}
                        style={{ fontFamily: 'Arial', fontSize: '14px' }}
                      >
                        Episode {index + 1}
                      </Option>
                    )
                  )}
              </Select>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Button
              style={{ margin: '10px', padding: '10px', width: '50%', fontFamily: 'Arial', fontSize: '14px' }}
              disabled={!selectedSeason || !selectedEpisode}
              onClick={handleWatch}
            >
              {/* Watch Season {selectedSeason}, Episode {selectedEpisode} */}
              Watch Now!
            </Button>
            <Button
              style={{ margin: '10px', padding: '10px', width: '50%', fontFamily: 'Arial', fontSize: '14px' }}
              onClick={() => window.open(`https://www.youtube.com/watch?v=${videos[0].key}`)}
            >
              <IconWrapper>
                <PlayIcon />
              </IconWrapper>
              Watch Trailer
            </Button>
          </div>
        </div>
      )}
    </Row>
  );
};

export default SeriesButton;


