import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

const WatchSeries = () => {
  const { id, s_no, e_no } = useParams<{ id: string; s_no: string; e_no: string }>();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      // Wait for the iframe to load
      iframe.onload = () => {
        // Inject JavaScript into the iframe to intercept link clicks
        iframe.contentWindow?.addEventListener('click', (event) => {
          const target = event.target as HTMLAnchorElement;
          if (target.tagName === 'A' && target.href) {
            event.preventDefault();
            // Optionally handle the link click here, e.g., open in a new tab
            window.open(target.href, '_blank');
          }
        });

        // Use postMessage to communicate with the iframe
        iframe.contentWindow?.postMessage('play', 'https://www.2embed.cc');
      };
    }
  }, [id, s_no, e_no]);

  return (
    <div className="fixed inset-0 flex items-center justify-center">
  <iframe
    // ref={iframeRef}
    src={`https://www.2embed.cc/embedtv/${id}&s=${s_no}&e=${e_no}`}
    title="Watch Video"
    allowFullScreen
    frameBorder="0"
    className="w-screen h-screen"
    allow="autoplay; fullscreen"
    referrerPolicy="strict-origin"
  ></iframe>

    </div>
  );
};

export default WatchSeries;
