import { useParams } from 'react-router-dom';

const WatchMovie = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <iframe
        src={`https://www.2embed.cc/embed/${id}`}
        title="Watch Video"
        allowFullScreen
        frameBorder="0"
        className="w-screen h-screen"
        allow="autoplay; fullscreen"
        referrerPolicy="strict-origin"
      ></iframe>
    </div>
  );
};

export default WatchMovie;
